/* Font copied in place by React Studio. Please check that your license for this font file permits embedding on a website. */
@font-face {
    font-family: 'HaloHandletter';
    src: url('../../fonts/HaloHandletter.ttf');
}

.font-haloHandletter {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}

@media (min-resolution: 120dpi) {
    .font-haloHandletter {
        font-family: 'HaloHandletter', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
    }
}

/* Font copied in place by React Studio. Please check that your license for this font file permits embedding on a website. */
@font-face {
    font-family: 'OpenSans';
    src: url('../../fonts/OpenSans-Regular.ttf');
}

.font-openSans {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}

@media (min-resolution: 120dpi) {
    .font-openSans {
        font-family: 'OpenSans', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
    }
}

/* Font copied in place by React Studio. Please check that your license for this font file permits embedding on a website. */
@font-face {
    font-family: 'OpenSans-CondensedBold';
    src: url('../../fonts/OpenSans-CondBold.ttf');
}

.font-openSansCondensedBold {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}

@media (min-resolution: 120dpi) {
    .font-openSansCondensedBold {
        font-family: 'OpenSans-CondensedBold', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
    }
}

.App { /* Base font style */
    font-family: 'OpenSans', sans-serif;
    font-size: 15.1px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: rgba(0, 0, 0, 0.8500);
    background-color: #f6f6f6;
}

.App .baseFont * { /* Base font style override for UI framework components */
    font-family: 'OpenSans', sans-serif;
    font-size: 15.1px;
}

.App .appBg {
    background-color: #f6f6f6;
}

.App .cardBg {
    background-color: white;
}

.App .baseTextBg { /* Base text color as background */
    background-color: rgba(0, 0, 0, 0.8500);
}

.App .secondaryTextColor {
    color: rgba(0, 0, 0, 0.5000);
}

.App .secondaryTextBg {
    background-color: rgba(0, 0, 0, 0.5000);
}

.App .primaryTextColor {
    color: #bee9ee;
}

.App .primaryBg {
    background-color: #bee9ee;
}

.App .highlightTextColor {
    color: #0093d5;
}

.App .highlightBg {
    background-color: #0093d5;
}

/* Styles to customize the UI framework plugin (MUI, https://muicss.com) */
.mui-btn {
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.mui-btn--primary {
    background-color: #bee9ee;
}

.mui-btn--primary:active, .mui-btn--primary:focus, .mui-btn--primary:hover {
    background-color: #c3ebf0;
}

.mui-btn--accent {
    background-color: #0093d5;
}

.mui-btn--accent:active, .mui-btn--accent:focus, .mui-btn--accent:hover {
    background-color: #069fda;
}

.mui-textfield { /* Reset UI framework styling that affects layout */
    padding-top: 0;
    margin-bottom: 0;
}

.mui-checkbox, .mui-radio { /* Reset UI framework styling that affects layout */
    margin-top: 0;
    margin-bottom: 0;
}

.mui-select { /* Reset UI framework styling that affects layout */
    padding-top: 0;
    margin-bottom: 0;
}

.mui-btn + .mui-btn { /* Reset UI framework styling that affects layout */
    margin-left: 0;
}

.mui-btn--fab { /* Reset UI framework styling that breaks compositing */
    z-index: auto;
}

@keyframes fadeIn {
    0% {
        opacity: 0.0;
    }
    100% {
        opacity: 1.0;
    }
}

@keyframes slideIn_right {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes slideIn_bottom {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
}

/* App-wide text styles specified in React Studio */
.App .actionFont {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
    font-size: 17.2px;
    font-weight: 400;
}

.App .actionFont * {
    font-size: 17.2px;
}

.App .headlineFont {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
    font-size: 19.4px;
    font-weight: 600;
}

@media (min-resolution: 120dpi) {
    .App .headlineFont {
        font-family: 'OpenSans-CondensedBold', sans-serif;
    }
}

.App .headlineFont * {
    font-size: 19.4px;
}

.App .navBar {
    background-color: #bee9ee;
    color: black;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 63px;
    min-height: 63px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
    font-size: 18px;
}

.App .navBar a {
    color: black;
}

.App .navBar .title {
    position: absolute;
    width: 100%;
    text-align: center;
    padding: 4px;
    margin-top: 13px;
}

.App .navBar .backBtn {
    position: absolute;
    padding: 4px;
    margin-left: 14px;
    margin-top: 13px;
    cursor: pointer;
}

@media (min-width: 320px) {
    .mui-container {
        max-width: 320px;
    }
}

@media (min-width: 568px) {
    .mui-container {
        max-width: 568px;
    }
}

@media (min-width: 768px) {
    .mui-container {
        max-width: 768px;
    }
}

@media (min-width: 1024px) {
    .mui-container {
        max-width: 1024px;
    }
}

.AppScreen {
    padding-left: 0;
    padding-right: 0;
    position: absolute;
    width: 100%;
}

.App .background {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.App .layoutFlow {
    position: relative;
    width: 100%;
    pointer-events: none;
}

.App .layoutFlow:before { /* Prevent margin collapse */
    content: "";
    display: table;
    clear: both;
}

.App .layoutFlow .flowRow {
    position: relative;
    width: 100%;
}

.App .screenFgContainer {
    /* Items in the foreground group within a screen are placed with fixed positioning */
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.App .foreground {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.App ul {
    margin: 0;
    padding: 0;
}

.App li {
    margin: 0;
    padding: 0;
    list-style-type: none;
    position: relative;
}

.App .hasNestedComps {
    position: relative;
}

.App .hasNestedComps:after {
    content: "";
    display: table;
    clear: both;
}

.App .hasNestedComps .background {
    position: absolute;
}

.App .hasNestedComps .layoutFlow {
    position: relative;
}

.App .bottomAlignedContent {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.App .blockInputOverlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: auto;
    z-index: 99999999;
}

